import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { A, Page, SEO, H4, P, StyledDate } from '../../../../packages/shared';

export const query = graphql`
  query {
    allMdx {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "ll")
            slug
          }
          excerpt
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`;

const EntryWrapper = styled('div')``;

const IndexPage = ({ data }) => {
  return (
    <Page>
      <SEO title="Erica's Blog" siteData={data.site} />

      {data.allMdx.edges.map(({ node }) => {
        const { id, excerpt, fields, frontmatter } = node;

        return (
          <EntryWrapper key={id}>
            <StyledDate>{frontmatter.date}</StyledDate>
            <H4>
              <A href={`${fields.slug}`}>{frontmatter.title}</A>
            </H4>
            <P>{excerpt}</P>
          </EntryWrapper>
        );
      })}
    </Page>
  );
};

export default IndexPage;
